import { Apps, Environment } from '@awork/environments/types'

export const environment: Environment = 'local',
  apiEndpoint = 'https://api.cwork.io/api/v1',
  apiHostname = 'cwork.io',
  apiProtocol = 'https://',
  assetsUrl = '/assets/',
  clientSecret = 'LUoJXFBCgLDKXmp0BcXawA7osttZAGhZfkIYAyKafqZ3bfSkMtxk',
  mobileAppClientCredentials = '>>APP_CLIENT_CREDENTIALS',
  releaseName = 'Crispy Steel',
  buildName = 'Apollo',
  version = '1.3.1001',
  app: Apps = 'web',
  pushNotificationSenderId = '561151912625',
  isLiveMobile = false
