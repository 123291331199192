import { RemoveButtonComponent } from './../../icon-buttons/remove-button/remove-button.component'
import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { FileUpload, FileUploadStatus } from '@awork/_shared/models/file-upload.model'
import { WithGlobals } from '../../../classes/with-globals'
import { FileService } from '@awork/_shared/services/file-service/file.service'
import { FileSizePipe } from '../../../pipes/file-size/file-size.pipe'
import { NgSwitch, NgSwitchCase, NgSwitchDefault } from '@angular/common'
import { FileIconComponent } from '../../files/file-icon/file-icon.component'
import { IconButtonComponent } from '../../icon-buttons/icon-button/icon-button.component'
import { ProgressBarComponent } from '../../ui-help/progress-bar/progress-bar.component'
import { InfoBoxComponent } from '../../ui-help/info-box/info-box.component'
import { BadgeComponent } from '../../data-display/badge/badge.component'
import { TooltipDirective } from '../../../directives/tooltip/tooltip.directive'

@Component({
  selector: 'aw-file-upload-progress',
  templateUrl: './file-upload-progress.component.html',
  styleUrls: ['./file-upload-progress.component.scss'],
  standalone: true,
  imports: [
    FileIconComponent,
    NgSwitch,
    NgSwitchCase,
    NgSwitchDefault,
    ProgressBarComponent,
    IconButtonComponent,
    RemoveButtonComponent,
    FileSizePipe,
    InfoBoxComponent,
    BadgeComponent,
    TooltipDirective
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileUploadProgressComponent extends WithGlobals {
  @Input() fileUpload: FileUpload

  fileUploadStatuses = FileUploadStatus

  constructor(private fileService: FileService) {
    super()
  }

  /**
   * Sets the canceled flag to true to cancel the upload
   * @param {Event} event - The click event
   */
  cancelUpload(event: Event): void {
    event.stopPropagation()
    this.fileUpload.status = FileUploadStatus.Canceled
    this.fileService.fileUploadCanceled.next(this.fileUpload)
  }

  /**
   * Retries the file upload
   * @param {Event} event - The click event
   */
  retryUpload(event: Event): void {
    event.stopPropagation()
    this.fileService.retryUpload(this.fileUpload).subscribe()
  }
}
