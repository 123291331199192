import type { Notification } from '@awork/framework/models/notification.model'
import type { TransientEvents } from '@awork/_shared/models/transient-events.model'
import type { ComponentRef } from '@angular/core'
import type { ToastComponent } from '../../components/ui-help/toast/toast.component'

export type ToastTypes =
  | 'info'
  | 'action'
  | 'error'
  | 'event'
  | 'reminder'
  | 'confirmation'
  | 'message'
  | 'fileUpload'
  | 'thankYou'

export interface ToastOptions {
  title?: string
  subtitle?: string
  persist?: boolean
  action?: Function
  context?: any
  notification?: Notification
  actionText?: string
  type?: ToastTypes
  imageSrc?: string
  emoji?: string
  showRepeated?: boolean
  event?: TransientEvents
}

export interface ToastItem {
  toastRef: ComponentRef<ToastComponent>
  toast: ToastComponent
  id: number
}

export const TOAST_CLOSE_DELAY = 8000
