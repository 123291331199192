import { Project } from '@awork/features/project/models/project.model'
import { ProjectMember } from '@awork/features/project/models/project-member.model'
import { getMonthYearString, getWeekYearString } from '@awork/_shared/functions/date-operations'
import { GroupByOptions, ProjectSortByOptions } from '@awork/_shared/models/group-by-options.model'
import { SortingDirection } from '@awork/_shared/models/sort-by-option.model'

/**
 * Gets project sorting according to the group
 * @param {Project} projectA
 * @param {Project} projectB
 * @param {GroupByOptions} projectGrouping
 * @returns {number}
 */
export function getSortByGroup(projectA: Project, projectB: Project, projectGrouping?: GroupByOptions): number {
  let sortResult: number

  switch (projectGrouping) {
    case GroupByOptions.client:
      if (!projectA.company?.name) {
        sortResult = 1
        break
      }

      if (!projectB.company?.name) {
        sortResult = -1
        break
      }

      sortResult = projectA.company?.name.localeCompare(projectB.company?.name)
      break
    case GroupByOptions.projectStatus:
    case GroupByOptions.projectStatusType:
      if (
        projectA.projectStatus &&
        projectB.projectStatus &&
        projectA.projectStatus.typeOrder !== projectB.projectStatus.typeOrder
      ) {
        sortResult = projectA.projectStatus.typeOrder - projectB.projectStatus.typeOrder
      } else {
        sortResult = 0
      }
      break
    case GroupByOptions.projectType:
      sortResult = projectA.projectType?.name.localeCompare(projectB.projectType?.name)
      break
    case GroupByOptions.responsibleUser:
      const projectAResponsibleUser = projectA.members?.find(member => member.isResponsible)
      const projectBResponsibleUser = projectB.members?.find(member => member.isResponsible)

      if (!projectAResponsibleUser) {
        sortResult = 1
        break
      }

      if (!projectBResponsibleUser) {
        sortResult = -1
        break
      }

      const userA = new ProjectMember(projectAResponsibleUser)?.user
      const userB = new ProjectMember(projectBResponsibleUser)?.user

      sortResult = userA.fullName.localeCompare(userB.fullName)
      break
    case GroupByOptions.dueWeek:
    case GroupByOptions.dueMonth:
      if (!projectA.dueDate) {
        sortResult = 1
        break
      }

      if (!projectB.dueDate) {
        sortResult = -1
        break
      }

      const stringFunction = projectGrouping === GroupByOptions.dueWeek ? getWeekYearString : getMonthYearString
      if (
        stringFunction(projectA.dueDate, q.translations as any) ===
        stringFunction(projectB.dueDate, q.translations as any)
      ) {
        sortResult = 0
        break
      }

      sortResult = new Date(projectA.dueDate) > new Date(projectB.dueDate) ? -1 : 1
      break
    default:
      sortResult = 0
      break
  }

  if (sortResult === 0) {
    return projectA?.name.localeCompare(projectB?.name)
  }

  return sortResult
}

/**
 * Gets project sorting according to the sorting configuration
 * @param {Project} projectA
 * @param {Project} projectB
 * @param {ProjectSortByOptions} projectSorting
 * @returns {number}
 */
export function projectSortBy(
  projectA: Project,
  projectB: Project,
  projectSorting: ProjectSortByOptions,
  sortingDirection: SortingDirection = 'asc'
): number {
  let sortResult: number
  const direction = sortingDirection === 'asc' ? 1 : -1

  switch (projectSorting) {
    case ProjectSortByOptions.client:
      if (!projectA.company?.name) {
        sortResult = 1
        break
      }

      if (!projectB.company?.name) {
        sortResult = -1
        break
      }

      sortResult = projectA.company?.name.localeCompare(projectB.company?.name)
      break
    case ProjectSortByOptions.status:
      if (!projectA.projectStatus) {
        sortResult = 1
        break
      }

      if (!projectB.projectStatus) {
        sortResult = -1
        break
      }

      if (projectA.projectStatus.typeOrder === projectB.projectStatus.typeOrder) {
        sortResult = projectA.projectStatus.name.localeCompare(projectB.projectStatus.name)
      } else {
        sortResult = projectA.projectStatus.typeOrder - projectB.projectStatus.typeOrder
      }

      break
    case ProjectSortByOptions.dueDate:
      sortResult = sortByDate(projectA?.dueDate, projectB?.dueDate)
      break
    case ProjectSortByOptions.startDate:
      sortResult = sortByDate(projectA?.startDate, projectB?.startDate)
      break
    case ProjectSortByOptions.budget:
      sortResult = (projectA?.timeBudget || 0) - (projectB?.timeBudget || 0)
      break
    case ProjectSortByOptions.taskProgress:
      const progressA = projectA?.tasksCount ? projectA.tasksDoneCount / projectA.tasksCount : 0
      const progressB = projectB?.tasksCount ? projectB.tasksDoneCount / projectB.tasksCount : 0

      sortResult = progressA - progressB

      if (progressA === progressB) {
        sortResult = (projectA?.tasksCount || 0) - (projectB?.tasksCount || 0)
      }

      break
    case ProjectSortByOptions.timeProgress:
      const timeProgressA = projectA?.timeBudget ? (projectA.trackedDuration || 0) / projectA.timeBudget : 0
      const timeProgressB = projectB?.timeBudget ? (projectB.trackedDuration || 0) / projectB.timeBudget : 0

      sortResult = timeProgressA - timeProgressB

      if (timeProgressA === timeProgressB) {
        sortResult = (projectA?.trackedDuration || 0) - (projectB?.trackedDuration || 0)
      }

      break
    case ProjectSortByOptions.name:
    default:
      sortResult = projectA?.name.localeCompare(projectB?.name)
      break
  }

  // If the sorting result is the same, sort by name
  if (sortResult === 0) {
    sortResult = projectA?.name.localeCompare(projectB?.name)
  }

  return sortResult * direction
}

function sortByDate(dateA: Date | string, dateB: Date | string): number {
  const dateObjectA = new Date(dateA)
  const dateObjectB = new Date(dateB)

  return (dateObjectA?.getTime() || 0) - (dateObjectB?.getTime() || 0)
}
